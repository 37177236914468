var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-layer DAV-layer--danger"
  }, [_c('Dimmed', {
    on: {
      "passEmit": _vm.onHide
    }
  }), _c('div', {
    staticClass: "DAV-layer__wrap"
  }, [_c('p', {
    staticClass: "DAV-layer__text",
    domProps: {
      "innerHTML": _vm._s(_vm.getConfirm.message)
    }
  }), _c('div', {
    staticClass: "DAV-layer__btns"
  }, [_c('button', {
    staticClass: "DAV-layer__btn",
    on: {
      "click": _vm.onHide
    }
  }, [_vm._v("취소하기")]), _c('button', {
    staticClass: "DAV-layer__btn DAV-layer__btn--active",
    on: {
      "click": _vm.onMove
    }
  }, [_vm._v(" " + _vm._s(_vm.getConfirm.messageBtn) + " ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }