<template>
  <div class="DAV-layer DAV-layer--danger">
    <Dimmed @passEmit="onHide"></Dimmed>
    <div class="DAV-layer__wrap">
      <p class="DAV-layer__text" v-html="getConfirm.message"></p>
      <div class="DAV-layer__btns">
        <button class="DAV-layer__btn" @click="onHide">취소하기</button>
        <button class="DAV-layer__btn DAV-layer__btn--active" @click="onMove">
          {{ getConfirm.messageBtn }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");

export default {
  data() {
    return {};
  },
  computed: {
    ...ModuleConfirm.mapGetters(["getConfirm"])
  },
  methods: {
    // 취소
    onHide() {
      this.setConfirmVisible(false);
      this.$EventBus.$emit("confirmCancel", this.getConfirm.event);
    },
    // 실행
    onMove() {
      this.setConfirmVisible(false);
      // this.$EventBus.$emit("confirmExecute", this.getConfirm.event);
      this.getConfirm.event();
    },

    ...ModuleConfirm.mapMutations(["setConfirmVisible"])
  },
  components: {
    Dimmed: () => import("@/components/basis/dimmed.vue")
  }
};
</script>

<style lang="scss" scoped>
.DAV-layer {
  z-index: 10000004;

  .DAV-dimmed {
    z-index: 10000003;
  }

  &__wrap {
    z-index: 10000004;
    position: fixed;
    top: 50%;
    right: 16px;
    left: 16px;
    transform: translateY(-50%);
    max-width: 400px;
    margin: 0 auto;
    padding: 70px 16px 16px;
    background-color: #ffffff;
    border: 1px solid #d8dae5;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
  }
  &--danger &__wrap {
    background-color: #ffffff;
    background-image: url($path + "ico_modal_danger.svg");
    background-repeat: no-repeat;
    background-position: center 34px;
  }
  &__text {
    text-align: center;
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-top: 30px;
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 44px;
    color: #b7b7b7;
    font-size: 14px;
  }
  &__btn--active {
    background-color: #985fe8;
    border-radius: 8px;
    color: #ffffff;
  }
}
</style>
